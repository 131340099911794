angular.module('LeasePilot').directive('leaseAttachment', [
  '$rootScope',
  'AttachmentService',
  function($rootScope, AttachmentService) {
    const leaseAttachmentLink = function(scope, element, attrs) {
      scope.propName = scope.nameKey.split('.')[1];
      scope.dashCaseVal = scope.propName
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      scope.attachments = AttachmentService.attachments[scope.propName];
    
      var appScope = $rootScope.findAppScope(scope);
      scope.appScope = appScope;
    
      element.on('click', function(e) {
        if (appScope.inline.on) {
          var relateds = appScope.inline.findNestedControls(element);
    
          // remove yourself from array and reposition at beginning
          var myIdx = relateds.indexOf(attrs.name);
          if (myIdx > -1) {
            relateds.splice(myIdx, 1);
          }
          relateds.unshift(attrs.name);
    
          relateds = appScope.inline.util.checkRelateds(relateds, element);
          appScope.inline.mouseX = e.clientX;
          appScope.inline.mouseY = e.clientY;
          appScope.inline.openInline(element, relateds);
          e.stopPropagation(); // Stop so that the inline isn't opened by the lease-if that it is within
        }
      });
    
      scope.$watch('[name, isChanging]', function(newval) {
        if (newval) {
          scope.attachments = AttachmentService.attachments[scope.propName];
        }
      });
    };
    
    return {
      restrict: 'E',
      scope: {
        name: '=',
        nameKey: '@name',
        isChanging: '=',
      },
      template:
        "<div class='dashed inline-dashed' ng-if='appScope.inline.on && !attachments.length'>{{ $root.labels[nameKey] }}</div>" +
        "<div id='anchor-{{dashCaseVal}}' class='adding' ng-if='isChanging'>" +
        "<div ng-repeat='attachment in attachments | orderBy:\"index\" track by $index' style='text-align: center' class='center-block'>" +
        "<img id='{{dashCaseVal}}-image-{{$index}}' ng-src='{{ attachment.image }}' style='max-width: 100%;'/></div></div>" +
        "<div id='anchor-{{dashCaseVal}}' class='added' ng-if='!isChanging'>" +
        "<div ng-repeat='attachment in attachments | orderBy:\"index\" track by $index' style='text-align: center' class='center-block'>" +
        "<img id='{{dashCaseVal}}-image-{{$index}}' ng-src='{{ attachment.image }}' style='max-width: 100%;'/></div></div>",
      link: leaseAttachmentLink,
    };
  },
]);
